// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import url("https://fonts.googleapis.com/css?family=Outfit");
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
// @import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';
// Bootstrap
// Already imported by AdminLTE
// @import '~bootstrap/scss/bootstrap';

@import '~vue-select/dist/vue-select.css';

.form-group {
    align:center;
    text-align:center;
    max-width: 40%;
    margin: auto;
}
.form-control {
    text-align:center;
    align:center;
}
